<template>
  <div class="news">
    <el-carousel class="newsCarousel" :interval="5000" arrow="always">
      <el-carousel-item>
        <div class="carouselBox carouselBox4" @click="goNews(3)">
          <div class="carouselTxt">
            <p class="swiper_news_text_1"><span>诺德剪彩仪式</span></p>
            <p class="swiper_news_text_2"><span>慧言科技在河北区诺德中心举行入驻仪式暨“海河·谛听”大模型成果展示活动</span></p>
          </div>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <div class="carouselBox carouselBox3" @click="goNews(2)">
          <div class="carouselTxt">
            <p class="swiper_news_text_1"><span>“海河·谛听”言语交互大模型世界智能大会发布</span></p>
            <p class="swiper_news_text_2"><span>“海河·谛听”言语交互意图深度理解大模型成果发布</span></p>
          </div>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <div class="carouselBox carouselBox2" @click="goNews(1)">
          <div class="carouselTxt">
            <p class="swiper_news_text_1"><span>“海河·谛听”大模型签约仪式</span></p>
            <p class="swiper_news_text_2"><span>“海河·谛听”言语交互意图深度理解大模型三方签约仪式</span></p>
          </div>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <div class="carouselBox carouselBox1" @click="goNews(0)">
          <div class="carouselTxt">
            <p class="swiper_news_text_1"><span>搭建平台促融合 反哺母校现情怀</span></p>
            <p class="swiper_news_text_2"><span>慧言科技向天津大学捐赠人工智能实验室建设基金</span></p>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="newsContent">
      <div class="newsList">
        <ul>
          <li v-for="(item,index) in stuData" :key="index">
            <a :href="item.newsLink" target="_blank">
              <div class="imgContent" :style="{background: 'url(' + require('../assets/imgs/news/'+item.newsPic) + ') no-repeat center center'}"></div>
              <div class="txtContent">
                <p class="new_title" style="-webkit-box-orient: vertical!important;">{{ item.newsName }}</p>
                <span class="new_details" style="-webkit-box-orient: vertical!important;">{{ item.newsDetails }}</span>
                <span class="new_time">{{ item.newsTime }}</span>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <el-pagination
          class="pagePagination"
          background
          layout="prev, pager, next"
          prev-text="上一页"
          next-text="下一页"
          @prev-click="prevClick"
          @next-click="nextClick"
          @current-change="thisPage"
          :page-size="pageSize"
          :total="totle">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import news from "@/assets/json/news";
// import axios from "axios";
export default {
  name: "News",
  data () {
    let config = []
    Object.assign(config, news)
    return {
      news: config,
      totle: 0,
      list: [],
      pageSize: 8,
      currentPage: 0,
    }
  },
  mounted() {
    this.totle = this.news.data.length
    this.list = this.news.data
  },
  computed: {
    //分页数据
    stuData: function(){
      let left = this.currentPage*this.pageSize;
      let right = Math.min((this.currentPage+1)*this.pageSize, this.list.length)
      return this.list.slice(left, right);//取出一页数据
    },
  },
  methods: {
    goNews(val) {
      if (val == 0) {
        window.open('http://www.tht.gov.cn/contents/23/110988.html','_blank')
      } else if (val == 1) {
        window.open('https://mp.weixin.qq.com/s?__biz=MzU3ODU0OTUyNg==&mid=2247483864&idx=1&sn=191db29a3aadb1e7d0f0c793b320bd1c&chksm=fd72e3a1ca056ab7a79811db9e87d29e08a24f936bfdb94d3f96a3f0a78832c6c4d2cc90e0aa&mpshare=1&scene=1&srcid=1030Eg60mTUVbxIejZhgptYJ&sharer_sharetime=1604037229959&sharer_shareid=04c43ba5ddc6bef924891e83e460faa0&key=03d33f7d4e0fa9ba15878af2d76fe0ba741e26a681c7d9ce639fb317b955266f3ca27bc29f708fb3bc3b2ddf255426270da7ab4547ae052a96e88bf5c183928d8ddd8b166b9f5dcd95388ba57d4bab078ed5615109425048e45f6523de3524c4a45e22dee35895daccc051d6b516a977b62b78954a873f987c41a3cd66ecae2f&ascene=1&uin=MTExNTIyMjAyMQ%3D%3D&devicetype=Windows+10+x64&version=6300002f&lang=zh_CN&exportkey=AUnEyaQ3bLsWam%2FGAJ%2Bx6%2FY%3D&pass_ticket=AJc1%2BEPtUUGAi4STjW2Pe5T6saGxAV%2FWChErhcZ4%2B8fMwv4WVZhUQthbPiG%2Bqnvc&wx_header=0','_blank')
      }
    },
    //上一页
    prevClick: function(){
      if (this.currentPage == 0) return;
      this.currentPage--;
    },
    //下一页
    nextClick: function(){
      if (this.currentPage == this.pageNumber - 1) return;
      this.currentPage++;
    },
    //页码
    thisPage: function(index){
      this.currentPage = index-1;
    }
  }
}
</script>

<style lang="less" scoped>
.newsCarousel {
  /deep/ .el-carousel__container {
    height: 600px;
  }
  /deep/ .el-carousel__indicator {
    width: 20px;
    height: 20px;
    margin: 0 16px;
  }
  /deep/ .el-carousel__button {
    width: 20px;
    height: 20px;
    border-radius: 100%;
  }
  .carouselBox {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .carouselBox1 {
    background: url('~@/assets/imgs/news/news_poster1.jpg') no-repeat center center;
    background-size: auto 100%;
  }
  .carouselBox2 {
    background: url('~@/assets/imgs/news/news_poster2.png') no-repeat center center;
    background-size: auto 100%;
  }
  .carouselBox3 {
    background: url('~@/assets/imgs/news/news_poster3.png') no-repeat center center;
    background-size: auto 100%;
  }
  .carouselBox4 {
    background: url('~@/assets/imgs/news/news_poster4.png') no-repeat center center;
    background-size: auto 100%;
  }
  .carouselTxt {
    position: absolute;
    bottom: 50px;
    left: auto;
    right: 150px;
    height: 150px;
    padding: 10px 50px;
    background: rgba(0,0,0,0.6);
    .swiper_news_text_1{
      margin-top: 40px;
      margin-bottom: 15px;
    }
    .swiper_news_text_1 span{
      display: block;
      font-size: 22px;
      color: #fff;
    }
    .swiper_news_text_2 span{
      display: block;
      font-size: 18px;
      color: #fff;
    }
  }
  /deep/ button {
    i {
      color: #007AFF;
      font-size: 50px;
    }
  }
}
.newsContent {
  width: 100%;
  padding-bottom: 50px;
  .newsList {
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    ul {
      display: flex;
      flex-wrap: wrap;
      margin: 80px 0 0;
      li {
        position: relative;
        width: 100%;
        height: 230px;
        background: #FFFFFF;
        box-shadow: 0 3px 10px 0 rgba(0,0,0,0.1);
        border-radius: 6px;
        margin-bottom: 60px;
        a {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          background: #f4f5fa;
          cursor: pointer;
          border: 1px solid transparent;
          .imgContent {
            width: 308px;
            height: 230px;
            background-size: 100% 100%!important;
          }
          .txtContent {
            width: 890px;
            .new_title {
              display: -webkit-box;
              height: 29px;
              padding: 0 70px;
              font-size: 22px;
              color: #333;
              -webkit-line-clamp: 1;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            .new_details {
              display: -webkit-box;
              height: 42px;
              padding: 0 70px;
              margin-top: 20px;
              line-height: 21px;
              font-size: 16px;
              color: #888;
              -webkit-line-clamp: 2;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            .new_time {
              display: block;
              padding: 0 70px;
              margin-top: 20px;
              font-size: 16px;
              color: #aaa;
            }
          }
        }
        a:hover{
          border: 1px solid #557aed;
        }
        a:hover .new_title{
          color: #127dbb;
        }
      }
      li:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
  .pagePagination {
    text-align: center;
    /deep/ button {
      width: 74px;
      height: 38px;
    }
    /deep/ li {
      width: 38px;
      height: 38px;
      line-height: 38px;
    }
  }
}
</style>